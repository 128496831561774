import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                <span>| </span>
                <a href="/nordic-berry/" className="link">
                  Nordic Berry
                </a>
                <span />
                <span>|</span>
                <a href="/plet-s-nedokonalostmi/">Pleť s nedokonalostmi</a>
                <span />
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/47/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup> Norská receptura Výživný tělový
                    balzám Nordic Berry
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NTG-bcpversion2a.jpg"
                      alt="NTG bcpversion2a"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup> Norská receptura Výživný tělový
                      balzám Nordic Berry
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                        <span data-mce-mark="1">
                          <span data-mce-mark="1">
                            Je klinicky prokázáno, že unikátní výživná receptura
                            tělového balzámu obohacená o vitamín E a ostružiník
                            moruška&nbsp;
                          </span>
                        </span>
                        <span data-mce-mark="1">spolu </span>
                        <span data-mce-mark="1">
                          s přirozenými procesy pokožky až trojnásobně zvyšuje
                          stupeň hydratace pokožky
                        </span>
                        <span data-mce-mark="1">
                          * a suchou pokožku přeměňuje v hebkou a vyživenou
                          každý den.&nbsp; Vyživuje i tu nejvysušenější pokožku
                          a dává jí tak krásný zdravý vzhled. Balzám je vhodný
                          pro
                        </span>
                        <span data-mce-mark="1">&nbsp;citlivou pokožku. </span>
                        <span data-mce-mark="1">
                          Krémová, nemastná textura s jemnou vůní se rychle
                          vstřebává.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span data-mce-mark="1">
                          <span data-mce-mark="1">
                            * hladina hydratace pokožky 7 hodin po aplikaci
                          </span>
                        </span>
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Nanášejte denně na celé tělo, ideálně po koupeli či
                      sprchování.{" "}
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>200 ml</dd>
                  </dl>
                </div>
                <div id="fb_11"></div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobrazit související produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/55/"
                  >
                    <img
                      src="/assets/Uploads/NTG-Lipp-LSF4-48g-bezblistru2.png"
                      alt="NTG Lipp LSF4 48g bezblistru2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Balzám na rty
                        SPF 4
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/56/"
                  >
                    <img
                      src="/assets/Uploads/Nutrogena-LSF-20-SUN.png"
                      alt="Nutrogena LSF 20 SUN"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Balzám na rty
                        SPF 20
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/71/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-Visibly-Clear-emulze-grapefruit-200ml.jpg"
                      alt="NEUTROGENA Visibly Clear emulze grapefruit 200ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Pink
                        Grapefruit Mycí emulze
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/72/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-Visibly-Clear-kremova-emulze-grepfruit-150ml.jpg"
                      alt="NEUTROGENA Visibly Clear kremova emulze grepfruit 150ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Pink
                        Grapefruit Krémová čisticí emulze
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/73/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-Visibly-Clear-peeling-grapefruit-150ml.jpg"
                      alt="NEUTROGENA Visibly Clear peeling grapefruit 150ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Pink
                        Grapefruit Peeling
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
